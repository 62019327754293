import {useEffect,useState} from 'react';
import { useNavigate ,useParams} from "react-router-dom";


import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import FoodService from '../service/Food'
import ProjectService from '../service/Project'
import MeasurementService from '../service/Measurement'

function formatJson(json){
	return JSON.stringify(json);
}

const Food = () => {
	
	//let params = useParams();
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	const [foodData,setFoodData]=useState([]);//used for view/edit
	const [tempFoodData,setTempFoodData]=useState({});//used for new
	const [foodFound,setFoodFound]=useState(false);
	const [foodTranslations,setFoodTranslations]=useState(false);
	const [smartPortionData,setSmartPortionData]=useState([]);//used for view/edit
	let params = useParams();
	let food_id =params.id;
	console.log('food_id',food_id);
	const [foodId,setFoodId]=useState(food_id);
	
	let method =params.method;
	console.log('method',method);
	const [methodType,setMethodType]=useState(method.charAt(0).toUpperCase() + method.slice(1));
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force chan ge Password
						if(currentUser.force_password_change){
								
						
						}else{
							if(typeof project_id !=="undefined"){//project filter
								loadProjectById(project_id);
							}
							loadFoodTranslation();
							loadFoodGroups();
							if(methodType=='New'){
								
							}else if(methodType=='Edit'){
								loadFoodById(food_id);
							}else if(methodType=='View'){
								loadFoodById(food_id);
								
							}else if(methodType=='Clone'){
								loadFoodById(food_id);
							}
							
							
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
							
								
							}else if(account_type=='Researcher'){//Researcher
			 				
							}
						}
					}
				}	
		}
	}, []);
	
	function loadFoodById(food_id){
			FoodService.getById(food_id).then(function(res){
				var _food=res.data;
				console.log('food',_food);
				loadSmartPortionsById(_food.code);
				setFoodData(res.data);
				if(methodType=='Clone'){
					delete _food['_id']; 
					delete _food['name']; 
					//_food.code =_food.code+'_1';
				}
				console.log(_food);
				setFoodFound(true);
			}).catch(function(error){
					console.log(error);
					setFoodFound(false);
			});
		}
		
	
		
	function loadFoodTranslation(){
			FoodService.getTranslations().then(function(res){
				setFoodTranslations(res.data);
				console.log(res.data);
				createTemplate(res.data);
			}).catch(function(error){
					console.log(error);
			});
		}
	
	const [foodGroups,setFoodGroups]=useState([]);	
	function loadFoodGroups(){
			FoodService.getFoodGroups().then(function(res){
				setFoodGroups(res.data);
				console.log(res.data);
			}).catch(function(error){
					console.log(error);
			});
	}
	
	const removeKeys=function(object,keys){
		var obj={};
		for (let key in object) {
		  if(!keys.includes(key)){
			  obj[key]=object[key];
		  }
		}
		return obj;
	}
	
	function loadProjectById(project_id){
			ProjectService.getById(project_id).then(function(res){
				setProjectData(res.data);
				console.log(res.data);
				setProjectFound(true);
			}).catch(function(error){
					console.log(error);
					setProjectFound(false);
			});
		}
		
	const handleOnChangeValue = (e,key) => {
		console.log(e.target.value,key);
		setFoodData({...foodData,
			[key]: e.target.value,
		})
	};
	//handleSendNewFoodRequest
	const handleSendNewFoodRequest = (e) => {
		console.log('saving.....',foodData);
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			foodData.global=false;
			foodData.project=project_id;
		}else{
			foodData.global=true;
			foodData.project=null;
		}
		foodData.requested=true;
		foodData.requested_by=AuthService.getCurrentUser()._id;
		foodData.created_by=null;
		foodData.processed=false;
		foodData.approved=false;
		foodData.active=true;
		
		FoodService.create(foodData).then(function(res){
				
			console.log(res.data);
			navigate("/food/view/"+res.data._id);
			navigate(0);//refresh page
				
		}).catch(function(error){
				console.log(error);
		});
	};
	
	//handleSaveNewFood
	const handleSaveNewFood = (e) => {
		console.log('saving.....',foodData);
		if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
			foodData.global=false;
			foodData.project=project_id;
		}else{
			foodData.global=true;
			foodData.project=null;
		}
		foodData.requested=false;
		foodData.requested_by=null;
		foodData.created_by=AuthService.getCurrentUser()._id;
		foodData.processed=true;
		foodData.approved=true;
		foodData.active=true;
		
		FoodService.create(foodData).then(function(res){
				
			console.log(res.data);
			navigate("/food/view/"+res.data._id);
			navigate(0);//refresh page
				
		}).catch(function(error){
				console.log(error);
		});
		
	
	};
	
	const handleUpdateFood = (e) => {
		console.log('updating.....',foodData);
		FoodService.update(foodData).then(function(res){
				
			console.log(res.data);
			navigate("/food/view/"+res.data._id);
			navigate(0);//refresh page
				
		}).catch(function(error){
				console.log(error);
		});
		
	};
	
	//generate food template
	function createTemplate(translations){
		//name
		setTempFoodData(prevState => ({
			...prevState,
			'name': '',
		}))
		//code
		setTempFoodData(prevState => ({
			...prevState,
			'code': '',
		}))
		//code
		setTempFoodData(prevState => ({
			...prevState,
			'group': '',
		}))
		//rest of variables
		for (let key in removeKeys(translations,['code','name','group'])) {
			//console.log(key+'=>'+translations[key]);
			setTempFoodData(prevState => ({
				...prevState,
				[key]: 0,
			}))
		}
	}
	
	/* smart portions */
	function loadSmartPortionsById(food_code){
			MeasurementService.getByFilter({'food_code':food_code}).then(function(res){
				console.log('smart potions',res.data);
				setSmartPortionData(res.data);
				//var _food=res.data;
				//setFoodData(res.data);
				//if(methodType=='Clone'){
				//	delete _food['_id']; 
				//	delete _food['name']; 
					//_food.code =_food.code+'_1';
				//}
				//console.log(_food);
			//	setFoodFound(true);
			}).catch(function(error){
					console.log(error);
					//setFoodFound(false);
			});
		}
		
	function getImageUrl(img_name){
		return MeasurementService.getImageUrl(img_name);
	}
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	<br/>
	{(projectFound) ?
	<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		<Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>

		 <Link underline="hover" color="inherit" href={`/foods`} > Foods </Link>
		<Typography color="text.primary">{methodType} food</Typography>
      </Breadcrumbs>
	
	</>
	:<>
	<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"> Home </Link>
		 <Link underline="hover" color="inherit" href="/foods" > Foods  </Link>
		<Typography color="text.primary">{methodType} food</Typography>
      </Breadcrumbs>
	</>}
	
	  
	<br/>
	  <Divider />

	
	
	
	{/*NEW, VIEW or EDIT */}
	{(methodType=='New') ?
	<>

	<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
	  	{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
			  
        <Button  onClick={handleSaveNewFood}  variant="contained" endIcon={<SaveIcon/>}>
		  Save
		</Button>
		
		</>
		:<></>}
		
		{/* Researcher */}
		{(currentUser.__t=='Researcher') ?
		<>
			  
        <Button  onClick={handleSendNewFoodRequest}  variant="contained" endIcon={<SendIcon/>}>
		  Send Request
		</Button>
		
		</>
		:<></>}
		  

      </Box>
    </div>
	
	
	 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
		  {/*<TableHead>
          <TableRow>
            <TableCell>Typical Values</TableCell>
            <TableCell align="left">Per 100g</TableCell>
          </TableRow>
	</TableHead>*/}
        <TableBody>
		<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Code
	</TableCell>*/}
	
		{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'code')}} value={foodData['code']} id="standard-basic" label="Code" variant="standard" />
	 
			  </TableCell>
			</>
		:<></>}  
			  
			  
            </TableRow>
			<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Name
	</TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'name')}} value={foodData['name']} id="standard-basic" label="Name" variant="standard" />
	 
			  </TableCell>
			  
            </TableRow>
			
			<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Name
	</TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'group')}} value={foodData['group']} id="standard-basic" label="Food Group" variant="standard" />
	 
			  </TableCell>
			  
            </TableRow> 
			
          {Object.entries(removeKeys(foodTranslations,['code','name','group'])).map(([key, value]) => ( 
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                {value}
		  </TableCell>*/}
              <TableCell align="left">
			  
			  <TextField onChange={(e) => {handleOnChangeValue(e,key)}} value={foodData[key]} id="standard-basic" label={value} variant="standard" />
			  
			  </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
		<br/><br/>
	</>
	:(methodType=='View') ?
	<>
	{/* error no food found */}
	{(!foodFound) ?
		<>
		No food found
		
		</>
	:<></>}
	{/* if food is found */}
	{(foodFound) ?
		<>
		<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
	  	{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
			  
        <Button href={`/food/edit/${foodData._id}`}  variant="contained" endIcon={<EditIcon/>}>
		  Edit
		</Button>
		
		</>
		:<></>}
		
		{/* Researcher */}
		{(currentUser.__t=='Researcher') ?
		<>
		{/*
        <Button href={`/food/edit/${foodData._id}`}   variant="contained" endIcon={<EditIcon/>}>
		  Edit
		</Button>
		*/}
		</>
		:<></>}
		  

      </Box>
    </div>
		
	<br/>
	
	
	{/* Researcher */}
		{(currentUser.__t=='Researcher') ?
		<>

		{(foodData.requested)?
		<>
			{(foodData.processed) ?
			<>
			  
			</>
			:<>
			<Alert severity="info">Awaiting approval.</Alert>
			</>}
			
		</>
		:<>
		</>}
		
		</>
		:<></>}

	<br/>
		<Typography variant="h5" gutterBottom>
			{foodData.code} - {foodData.name}
		</Typography>
		<b>Food Group:</b> {foodData.group}
		
	<Typography variant="h6" gutterBottom>
			Nutritional Information
		</Typography>
		<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Typical Values</TableCell>
            <TableCell align="left">Per 100g</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(removeKeys(foodTranslations,['code','name','group'])).map(([key, value]) => ( 
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {value}
              </TableCell>
              <TableCell align="left">
				
			  {foodData[key]}
			  
			  </TableCell>
			  
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
		<br/><br/>
		{/*
			<Typography variant="h6" gutterBottom>
			Smart Portions
		</Typography>
		<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Value g</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {smartPortionData.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index}
              </TableCell>
              <TableCell align="left">
				<img src={`${getImageUrl(row.img_name)}`}></img>
					{formatJson(row.img_details)}
			  </TableCell>
			   <TableCell align="left">
			   {row.value}
			  </TableCell>
			  
            </TableRow>
          ))}
        </TableBody>
      </Table>
		</TableContainer>*/}
		</>
	:<></>}
	
	</>
	:(methodType=='Edit') ?
	<>
		<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
	  	{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
			  
        <Button  onClick={handleUpdateFood}  variant="contained" endIcon={<SaveIcon/>}>
		  Save
		</Button>
		
		</>
		:<></>}
		
		{/* Researcher */}
		{(currentUser.__t=='Researcher') ?
		<>
			  
        <Button  onClick={handleUpdateFood}  variant="contained" endIcon={<SaveIcon/>}>
		  Save
		</Button>
		
		</>
		:<></>}
		  

      </Box>
	  
	  {/* Master */}
		{(currentUser.__t=='Master') ?
		<>
		
		{(foodData.requested)?
		<>
			{(foodData.processed) ?
			<>
			  
			</>
			:<>
			<Alert severity="info">Awaiting your approval.</Alert>
			</>}
			
		</>
		:<>
		</>}
			  
        
		
		</>
		:<></>}
		<br/>
    </div>
	
		{(!foodFound) ?
			<>No food found</>
		:<>
		
		<Typography variant="h6" gutterBottom>
			Nutritional Information
		</Typography>
		 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
		  {/*<TableHead>
          <TableRow>
            <TableCell>Typical Values</TableCell>
            <TableCell align="left">Per 100g</TableCell>
          </TableRow>
	</TableHead>*/}
        <TableBody>
		<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Code
	</TableCell>*/}
	
		{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'code')}} value={foodData['code']} id="standard-basic" label="Code" variant="standard" />
	 
			  </TableCell>
			</>
		:<></>}  
			  
			  
            </TableRow>
			<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Name
	</TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'name')}} value={foodData['name']} id="standard-basic" label="Name" variant="standard" />
	 
			  </TableCell>
			  
            </TableRow>
			
			<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Name
	</TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'group')}} value={foodData['group']} id="standard-basic" label="Food Group" variant="standard" />
	 
			  </TableCell>
			  
            </TableRow>
          {Object.entries(removeKeys(foodTranslations,['code','name','group'])).map(([key, value]) => ( 
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                {value}
		  </TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,key)}} value={foodData[key]} id="standard-basic" label={value} variant="standard" />
			  </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
		<br/><br/>
		
		
		
		</>}
	
	</>
	:(methodType=='Clone') ?
		<>

	<div style={{ width: '100%' }}>
		<Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  
	  	{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
			  
        <Button  onClick={handleSaveNewFood}  variant="contained" endIcon={<SaveIcon/>}>
		  Save
		</Button>
		
		</>
		:<></>}
		
		{/* Researcher */}
		{(currentUser.__t=='Researcher') ?
		<>
			  
        <Button  onClick={handleSendNewFoodRequest}  variant="contained" endIcon={<SendIcon/>}>
		  Send Request
		</Button>
		
		</>
		:<></>}
		  

      </Box>
    </div>
	
	
	 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
		  {/*<TableHead>
          <TableRow>
            <TableCell>Typical Values</TableCell>
            <TableCell align="left">Per 100g</TableCell>
          </TableRow>
	</TableHead>*/}
        <TableBody>
		<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Code
	</TableCell>*/}
	
		{/* Master */}
		{(currentUser.__t=='Master') ?
		<>
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'code')}} value={foodData['code']} id="standard-basic" label="Code" variant="standard" />
	 
			  </TableCell>
			</>
		:<></>}  
			  
			  
            </TableRow>
			
			<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Name
	</TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'name')}} value={foodData['name']} id="standard-basic" label="Name" variant="standard" />
	 
			  </TableCell>
			  
            </TableRow>
			
			<TableRow
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                Name
	</TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,'food_goup')}} value={foodData['food_goup']} id="standard-basic" label="Food Group" variant="standard" />
	 
			  </TableCell>
			  
            </TableRow>
			
          {Object.entries(removeKeys(foodTranslations,['code','name','group'])).map(([key, value]) => ( 
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
			{/* <TableCell component="th" scope="row">
                {value}
		  </TableCell>*/}
              <TableCell align="left">
			  <TextField onChange={(e) => {handleOnChangeValue(e,key)}} value={foodData[key]} id="standard-basic" label={value} variant="standard" />
			  </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
		<br/><br/>
	
	
	
	</>
	:<></>}
	
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default Food;