import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Account/Invite';

const AccountInviteService = {
	//public
	getInvite: function(invite) {
		console.log(invite);
		var qs = serialize(invite);
		return axios.get(Config.api_url+'/public/'+entity+'?'+qs);
    },
	//private
	create: function(request) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity,request,header);
    },
    getAll: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity,header);
    },
    getById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/'+id,header);
    },
    getByFilter: function(filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		return axios.get(Config.api_url+'/'+entity+'/filter/params?'+qs,header);
    },
    update: function(request) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/'+request._id,request,header);
    },
    updateRequest: function(request) {//accept/reject request 
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/request/'+request._id,request,header);
    },
    deleteById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/'+id,header);
    },
	sendMasterInvite: function(invite) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity+'/master/invite',invite,header);
    },
};

function serialize(obj) {
			 var str = [];
			  for (var p in obj)
				if (obj.hasOwnProperty(p)) {
				  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
				}
			  return str.join("&");
		}
		
export default AccountInviteService;